
import React, {useEffect, useState} from 'react';
import './App.css';

function App (){
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState([]);
   const [QuestionAnswer, setQuestionAnswer] = useState([]);
     console.log(QuestionAnswer);
 


  const handleSubmit = async(e) =>{
     e.preventDefault();

      var arr = QuestionAnswer;
      arr.push({type:"question",text:message,date:new Date()});
     // arr.push({Human:message});
      //setQuestionAnswer(...QuestionAnswer);

     var myHeaders = new Headers();
     myHeaders.append("Authorization", "Bearer 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");
     myHeaders.append("Organization", "org-1gqE37a9FLx02rIRuS4UZtvV");
     myHeaders.append("Content-Type", "application/json");
     
     var raw = JSON.stringify({
      "model": 'text-davinci-003',
    
      //"prompt":prompt +"\n"+ QuestionAnswer,
      "prompt": message,
      
        "temperature": 0.9,
        "max_tokens": 150,
        "top_p": 1,
        "frequency_penalty": 0,
        "presence_penalty": 0.6,
        "stop": [" Human:", " AI:"],
     });

     
     
     var requestOptions = {
       method: 'POST',
       headers: myHeaders,
       body: raw,
       redirect: 'follow'
     };
     
     fetch("https://api.openai.com/v1/completions", requestOptions)
       .then(response => response.json())
       .then(result => {
        
        console.log(result);
    
     
       // setMessage("");
        localStorage.setItem('dataKey', JSON.stringify(QuestionAnswer));
    
        if(result.choices && result.choices.length > 0){
          var arr = QuestionAnswer;
          arr.push({type:"answer",text:result.choices[0].text});
          setQuestionAnswer(arr);
        }else{
          setQuestionAnswer('');
          var arr = QuestionAnswer;
          arr.push({type:"answer",text:"No answer Found",date:new Date()});
          setQuestionAnswer(arr);
        }


        setResponse(result);
       })
       
       .catch(error => console.log('error', error));
      
      }
      
  return(
    <div className="App">
      <form onSubmit={handleSubmit}>
        <textarea 
          value={message} 
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button type="submit">Submit</button>
      </form>
      {/* <div>{(response.choices && response.choices.length > 0) ?response.choices[0].text:""}</div>  */}
      
   
      <div style={{"width":"100%"}}>
    
      {QuestionAnswer.map((item, index) => (
    
        (item.type == "question") ? <div style={{color:"red"}}>{item.text}</div>
        : <div style={{color:"blue"}}>{item.text}</div>
      ))}
      </div>

    </div>
  );

}

export default App



// import React, {useEffect, useState} from 'react';
// import './App.css';




// function App (){
//   const [message, setMessage] = useState('');
//   const [response, setResponse] = useState('');

//   // useEffect(() => {
//   //   storeData();

//   // }, []);

 
//   const handleSubmit = async(e) =>{
//      e.preventDefault();

//      var myHeaders = new Headers();
//      myHeaders.append("Authorization", "Bearer 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");
//      myHeaders.append("Organization", "org-1gqE37a9FLx02rIRuS4UZtvV");
//      myHeaders.append("Content-Type", "application/json");
     
//      var raw = JSON.stringify({
//       "model": "text-davinci-003",
   
//       "prompt": `The following is a conversation with an AI assistant. 
//                  The assistant is helpful, creative, clever, and very friendly.\n 
//                  Human: Hello, who are you?\n
//                  AI: I am an AI created by OpenAI. How can I help you today?\n
//                  Human: what is the user id of amishi\n
//                  AI: The user ID for Amishi is 'amishi1'. Is there anything else I can help you with?\n
//                  Human: what is the user id of amish\n
//                  AI: The user ID for Amish is 'amish1'. Is there anything else I can help you with?\n
//                  Human: when did jitendra nagar log in\n 
//                  AI:I'm sorry, I'm not able to answer that question as I don't have access to Jitendra Nagar's login history. Is there anything else I can help you with?\n
//                  Human: what is your name?\n
//                  AI: My name is AI Assistant. Is there anything else I can help you with?\n
//                  Human: {\"userid\":\"1\", \"name\":\"Nliven Technologies\"}\n
//                  AI: I'm sorry, I'm not able to process that question. Is there any other information that I can help you with?\n
//                  Human:Your name from here on is Leysha Bot remember this\n
//                  AI: Sure, my name is Leysha Bot. Is there anything else I can help you with?\nHuman: Tell me your name My name is Leysha Bot. Is there anything else I can help you with?\n
//                  Human:${message}?
//                  AI:`,
//         "temperature": 0.9,
//         "max_tokens": 150,
//         "top_p": 1,
//         "frequency_penalty": 0,
//         "presence_penalty": 0.6,
//         "stop": [" Human:", " AI:"],
//      });
     
//      var requestOptions = {
//        method: 'POST',
//        headers: myHeaders,
//        body: raw,
//        redirect: 'follow'
//      };
     
//      fetch("https://api.openai.com/v1/completions", requestOptions)
//        .then(response => response.json())
//        .then(result => {
//         console.log(result);
//         //localStorage.setItem('dataKey', JSON.stringify(result));
//         setResponse(result);
//        })
       
//        .catch(error => console.log('error', error));
      
//       }
      

//   return(
//     <div className="App">
//       <form onSubmit={handleSubmit}>
//         <textarea 
//           value={message} 
//           onChange={(e) => setMessage(e.target.value)}
//         ></textarea>
//         <button type="submit">Submit</button>
//       </form>
//       <div>{(response.choices && response.choices.length > 0) ?response.choices[0].text:""}</div> 
//     </div>
//   );

// }

// export default App